<template>
  <div class="section">
      <div class="inner-section">        
        <div class="row">
          <!-- TEXTS -->
          <div class="texts-column col-lg-4 col-md-4 col-sm-12">
            <h1 class="mb-5" v-html="$t('SECTION_01_TITLE')"></h1>
            <button @click.prevent="$root.$emit('scrollerClicked', 'section04')" class="mb-5 btn btn-yellow" v-html="$t('SUBSCRIBE_NOW')"></button>
            <h3 class='mb-3' v-html="$t('SECTION_01_SUBTITLE')"></h3>
            <p v-html="$t('SECTION_01_DESCRIPTION')"></p>
          </div>

          <div class="col-lg-8 col-md-8 col-sm-12">

            <div class="row">
              <!-- DROPS -->
              <div class="drops-column p-0 col-lg-5 col-md-5 col-sm-12">            
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <div class="drop01">
                    <img src="/assets/images/section01/drop01.png" alt="Drop 01">                                    
                    <div class='drop-text'>
                      <h2 v-html="$t('DROP_01_TITLE')"></h2>
                      <p>
                        <span v-html="$t('FOR')"></span>
                        <span class="price mx-2">$ 1.16</span>
                        <span v-html="$t('DAILY')"></span>
                      </p>
                      <p>                    
                        <span class="price mr-2">$ 35</span>
                        <span v-html="$t('MONTHLY')"></span>
                      </p>
                    </div>
                  </div>

                  <div class="drop02">
                    <img src="/assets/images/section01/drop02.png" alt="Drop 02">
                    <div class='drop-text'>
                      <h2 v-html="$t('DROP_02_TITLE')"></h2>
                      <p v-html="$t('DROP_02_DESCRIPTION')"></p>
                    </div>
                  </div>
                </div>                
              </div>

              <!-- MOCKUP -->
              <div class="mockup-column p-0 col-lg-7 col-md-7 col-sm-12">
                <img class="mockup-image" src="/assets/images/section01/mockup.png" alt="Mockup">
                <img class="mockup-label" :src="`/assets/images/section01/selo-${$i18n.locale}.png`" alt="Label">
              </div>
            </div>
          </div>
        </div>
      </div>

      <img class='table' src="/assets/images/section01/table.jpg" alt="Table">
  </div>
</template>

<script>
export default {
  data(){
    return{

    }
  }
}
</script>

<style scoped lang="scss">

  .section{        
    padding: 0 6vw !important;
    min-height: calc(100vh - 125px) !important;     
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 

    background: url(/assets/images/section01/bg-blue.jpg) no-repeat center center; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  
  .table{
    margin: 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 0;
  }

  .inner-section{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;

    .texts-column,
    .drops-column,
    .mockup-column{
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .texts-column{                      
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-top: -5vw;
    }

    .drops-column{  
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .drop01, 
      .drop02{        
        position: relative;
        width: 100%;                        
        display: flex;
        justify-content: center;
        align-items: center;

        img{                              
          width: 100%;
          z-index: 0;
        }
        .drop-text{                              
          z-index: 1;
          position: absolute;
          color: #fff;
          width: 100%;
          text-align: center;
          h2, p{
            color: #fff;
            font-family: 'Myriad Pro' !important;
          }

          h2{
            font-weight: 700;
          }

          p{
            font-size: 1rem;
            margin-bottom: 0;

            &:last-child{              
              margin-top: 10px !important;
            }
          }

          .price{            
            font-weight: 700;
            font-size: 1.5rem;
          }
        }
      }

      .drop01{
        padding: 0 5vw;
        .drop-text{          
          padding-right: 3vw;
          padding-bottom: 2vw;
        }
      }
      .drop02{                             
        padding: 0 2vw;
        .drop-text{          
          h2{
            padding-left: 3vw;            
          } 
          p{
            padding-left: 8vw;            
          }         
        }
      }      
    }

    .mockup-column{      
      position: relative;      
      .mockup-image{
        position: relative;        
        width: 100%;
        z-index: 0;
      }

      .mockup-label{
        width: 130px;
        position: absolute;        
        bottom: 20px;
        right: 0;
        z-index: 1;
      }
    } 
    
  }

  @media only screen and (min-width: 768px) and (max-width: 1000px) {

    .inner-section{
    }
  }

  @media only screen and (max-width: 767px) {

    .inner-section{
      .texts-column{                              
        margin-top: 30px;
      }

      .drops-column{  
        .drop01{          
          padding: 0 15vw;
          .drop-text{          
            padding-right: 10vw;
            padding-bottom: 8vw;
          }
        }
        .drop02{                             
          padding: 0 10vw;
          .drop-text{          
            h2{
              padding-left: 6vw;            
            } 
            p{
              padding-left: 28vw;            
            }         
          }
        }      
      }

      .mockup-column{      
        text-align: center;    
        .mockup-image{
          width: 70%;          
        }

        .mockup-label{
          width: 100px;          
          bottom: 20px;
          right: 0;
        }
      } 
    }
  }

</style>